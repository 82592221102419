import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  products: [];
  count: number;
}

const initialState: State = {
  isLoading: true,
  error: false,
  products: [],
  count: 0
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProductStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getProductSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      products: action.payload.products,
      count: action.payload.count
    }),
    getProductFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    })
  }
});

export const { getProductStart, getProductSuccess, getProductFailure } = productSlice.actions;
export default productSlice.reducer;

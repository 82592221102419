//Define type of route

const ROUTE_PATHS = {
  SIGNIN: '/signin',
  DASHBOARD: '/',
  SELLERS: '/sellers',
  USERS: '/users',
  PRODUCTS: '/products',
  ERROR: '/error'
}

export default ROUTE_PATHS;

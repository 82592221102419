import { all, fork } from 'redux-saga/effects';
import admin from './adminSaga';
import user from './userSaga'
import product from './productSaga'
import seller from './sellerSaga'
import category from './categorySaga'

export default function* rootSaga() {
  yield all([
    fork (admin),
    fork (user),
    fork (product),
    fork (seller),
    fork(category)
  ]);
}

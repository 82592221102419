import {
  usePagination,
  useQuery,
  useResultsPerPage,
  useSearch,
  useSearchContext,
} from "@sajari/react-hooks";
import { Combobox } from "@sajari/react-components";
import { Pagination } from "@sajari/react-components";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Summary } from "@sajari/react-search-ui";
import Pagination1 from "@mui/material/Pagination";
import { Button, Chip } from "@mui/material";
import axios from "api/axiosController";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const ProductList = () => {
  const { results = [] } = useSearch();
  const { query, setQuery } = useQuery();
  const { resultsPerPage, setResultsPerPage } = useResultsPerPage();
  const { setPage } = usePagination();
  let categoriesData = useSelector((state: RootState) => state.categories.categoryList);
  const [resultItems, setResultItems] = useState([]);
  const { totalResults }: any = useSearchContext();
  const [paginationPage, setPaginationPage] = React.useState(1);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      width: 125,
      minWidth: 400,
      maxWidth: 450,
    },
    {
      field: "description",
      headerName: "Description",
      width: 125,
      minWidth: 400,
      maxWidth: 450,
    },

    {
      field: "saleprice",
      headerName: "Price",
      type: "number",
    },
  ];
  const postAddss = async (data: any[]) => {
    let uri = `/googleAdd/postAdds`;
    data.forEach(async (item) => {
      try {
        const res = await axios.post(uri, item);
        return res.data;
      } catch (e) {
        throw e;
      }
    });
  };
  const titelize = (title: string) => {
    return title
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/ /g, "_")
      .replace(/ *\([^)]*\) */g, "")
      .replace(/[()\\\/]/g, "");
  };

  const getCategory = (categoryValue:string) =>{
    let categoryNameValue = categoryValue;
    if(!!categoryValue){
      categoriesData.forEach((item:any)=>{
        if(item.categoryName === categoryValue){
          categoryNameValue = item.categoryId;
        }
        else{
          item.childData.forEach((ele:any)=>{
            if(ele.categoryName === categoryValue){
              categoryNameValue = ele.categoryId;
            }
            if(ele.categoryId === categoryValue){
              categoryNameValue = ele.categoryId;
            }
          })
        }
      })
    }
    return categoryNameValue;
  }

  const postAdds = () => {
    let data: any[] = [];
    selectedItems.forEach((item) => {
      let updatedData = {};
      let product = {
        offerId: item.id,
        title: item.title,
        description: item.description,
        brand:item.brand,
        contentLanguage: "en",
        targetCountry: "US",
        link: `https://paysfer.com/product-page/${item.id}/${titelize(
          item.title
        )}`,
        imageLink: item.productgroupimageurl1,
        channel: "online",
        availability: "in stock",
        googleProductCategory:(item.categoryId &&item.categoryId)?item.categoryId: getCategory(item.category),
        price: {
          value:item.price[0],
          currency: "USD",
        }
      };
      updatedData = {...product};
      if(item.saleprice && item.saleprice.length && item.saleprice.length[0]!==null){
        updatedData={...product,
          price: {
            value: item.saleprice,
            currency: "USD",
          }
        };
      }
      if (item.gtin && item.gtin.length && (!item.gtin.includes(null))&& (!item.gtin.includes(""))) {
        updatedData = { ...product, gtin: item.gtin[0] };
      } 
      if (item.mpn && item.mpn.length && (!item.mpn.includes(null))&& (!item.mpn.includes(""))) {
        updatedData = { ...product, mpn: item.mpn[0] };
      }
      if (item.condition &&(item.condition.toUpperCase() === "REFURBISHED" || item.condition.toUpperCase() === "NEW")) {
        updatedData = { ...product, condition: item.condition.toUpperCase() === "REFURBISHED"?"refurbished":"new" };
      }
      data.push(updatedData);
    });
    postAddss(data);
  };

  useEffect(() => {
    if (results.length > 0) {
      let data: any[] = [];
      results.forEach((item) => {
        data.push(item.values);
      });
      setResultItems(data as any);
    }
  }, [results]);
  useEffect(() => {
    setResultsPerPage(100);
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          width: "80%",
          zIndex: "10000",
        }}
      >
        <Combobox onChange={(value: any) => setQuery(value)} />
      </div>
      <br /> <br /> <br />
      <div style={{ display: "flex" }}>
        <div style={{ height: 500, width: "50%", overflow: "scroll" }}>
          <DataGrid
            rows={resultItems}
            onPageSizeChange={(newPage) => {}}
            columns={columns}
            autoHeight={true}
            checkboxSelection
            hideFooter={true}
            onSelectionModelChange={(selectionModel) => {
              let allValue: any[] = [];
              selectionModel.forEach((ele) => {
                let data = resultItems.filter((item: any) => item.id === ele);
                allValue.push(data[0]);
              });
              allValue.forEach((item: any) => {
                let flag = true;
                selectedItems.forEach((ele: any) => {
                  if (item.id === ele.id) {
                    flag = false;
                  }
                });
                if (flag) {
                  setSelectedItems([...selectedItems, item]);
                }
              });
            }}
          />
        </div>
        &nbsp;
        <div style={{ height: 500, width: "50%", overflow: "scroll" }}>
          <h1>Selected Products</h1>
          <br />
          {selectedItems.map((item: any) => (
            <div key={item.toString()}>
              <Chip
                key={item.toString()}
                label={item.title}
                onDelete={() => {
                  let clonedArray = JSON.parse(JSON.stringify(selectedItems));
                  let index = 0;
                  clonedArray.forEach((element: any, i: number) => {
                    if (element.id === item.id) {
                      index = i;
                    }
                  });
                  clonedArray.splice(index, 1);
                  setSelectedItems(JSON.parse(JSON.stringify(clonedArray)));
                }}
              />
              <br />
              <br />
            </div>
          ))}
        </div>
      </div>
      <br />
      <Pagination1
        count={Math.round(totalResults / 100)}
        page={paginationPage}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          setPage(value);
          setPaginationPage(value);
        }}
      />
      <Button
        color="secondary"
        style={{ float: "right", position: "relative", bottom: "24px" }}
        onClick={() => postAdds()}
      >
        Post Adds
      </Button>
    </div>
  );
};

export default ProductList;

import SignIn from '../components/Layout/SiteContent/Signin';
import Home from '../components/Layout/SiteContent/Home';
import { route } from 'types';
import ROUTE_PATHS from './routesPath';
import ProductList from 'components/Layout/SiteContent/Dashboard/ProductList';
import UserList from 'components/Layout/SiteContent/Dashboard/UserList';
import Sellers from 'components/Layout/SiteContent/Dashboard/SellerList';
import Error from 'components/Layout/SiteContent/common/Error'

const routes: route[] = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: <Home />
  },
  {
    path: ROUTE_PATHS.SIGNIN,
    component: <SignIn />
  },
  {
    path: ROUTE_PATHS.SELLERS,
    component: <Sellers />
  },
  {
    path: ROUTE_PATHS.USERS,
    component: <UserList />
  },
  {
    path: ROUTE_PATHS.PRODUCTS,
    component: <ProductList />
  },
  {
    path: ROUTE_PATHS.ERROR,
    component: <Error />
  }
];

export default routes;

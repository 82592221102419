import { combineReducers } from '@reduxjs/toolkit';
import admin from './admin';
import user from './user'
import product from './product'
import seller from './seller'
import categories from './categories'

const rootReducer = combineReducers({
    admin,
    user,
    product,
    seller,
    categories, 
});

export default rootReducer;
